<template>
<!-- 设备编辑  -->
  <div class="box100">
    <el-dialog title="设备编辑" :visible.sync="editDialog" width="600px" center class="gyDialog fromDialog" :close-on-click-modal="false" :before-close="newFormEditCancel" top="6vh">
      <div class="form_content">
        <div class="from_tab flexAC">
          <p>设备添加：</p>
          <el-select v-model="formEdit.type" placeholder="下拉选择接入方式" style="width: 50%;" disabled>
            <el-option
              v-for="item in downArray.methodOption"
              :key="item.type"
              :label="item.name"
              :value="item.type"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </div>

        <!-- 编辑表单 -->
        <div class="tabCont">
          <el-form :model="formEdit" :rules="editFormRule" ref="FromEdit">
            <el-form-item v-if="formEdit.type === '1'" label="设备IMEI/ID" prop="equimei">
              <el-input v-model="formEdit.equimei"  placeholder="请输入设备IMEI/ID" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '1'" label="设备型号" prop="equcode">
              <el-input v-model="formEdit.equcode"  placeholder="请输入设备型号" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '1'" label="设备类型" prop="equtype">
              <el-select v-model="formEdit.equtype" placeholder="下拉选择设备类型" style="width: 100%;" disabled>
                <el-option
                  v-for="item in downArray.equipTypeOption"
                  :key="item.equtypeid"
                  :label="item.equtypename"
                  :value="item.equtypeid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '1'" label="传输方式">
              <el-select v-model="formEdit.transmission" placeholder="下拉选择传输方式" style="width: 100%;" disabled>
                <el-option
                  v-for="item in downArray.transferOption"
                  :key="item.dictid"
                  :label="item.datavalue"
                  :value="item.dictid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '6'" label="设备厂家" prop="manufacturer">
              <el-select v-model="formEdit.manufacturer" placeholder="下拉选择设备厂家" style="width: 100%;" disabled>
                <el-option v-for="item in downArray.equipFactory" :key="item.dictid" :label="item.datavalue" :value="item.dictid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '2' || formEdit.type === '6'" label="设备序列号" prop="equimei">
              <el-input v-model="formEdit.equimei"  placeholder="请输入设备序列号" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '2' || formEdit.type === '6'" label="验证码" prop="equcode">
              <el-input v-model="formEdit.equcode"  placeholder="请输入验证码" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '2' || formEdit.type === '6'" label="Appkey" prop="headport">
              <el-input v-model="formEdit.headport"  placeholder="请输入Appkey" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '2' || formEdit.type === '6'" label="Secret" prop="rtspport">
              <el-input v-model="formEdit.rtspport"  placeholder="请输入Secret" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '3'" label="视频编码ID" prop="equimei">
              <el-input v-model="formEdit.equimei"  placeholder="请输入视频编码ID" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '3'" label="密码" prop="equcode">
              <el-input v-model="formEdit.equcode"  placeholder="请输入密码" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '3'" label="设备厂家"  prop="equmace">
              <el-select v-model="formEdit.equmace" placeholder="下拉选择设备厂家" style="width: 100%;">
                <el-option
                  v-for="(item) in downArray.cameraOption"
                  :key="item.datavalue"
                  :label="item.datavalue"
                  :value="item.datavalue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '4'" label="设备编码" prop="equimei">
              <el-input v-model="formEdit.equimei"  placeholder="请输入设备编码" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '5'" label="设备类型"  prop="equtype">
              <el-select v-model="formEdit.equtype" placeholder="下拉选择设备类型"  style="width: 100%;" disabled>
                <el-option
                  v-for="item in downArray.tcpEquTypeOption"
                  :key="item.equtypeid"
                  :label="item.equtypename"
                  :value="item.equtypeid">
                </el-option>
              </el-select>
            </el-form-item>
            <div style="width: 100%;" v-if="formEdit.equtype === '9' && formEdit.type === '1' || formEdit.equtype === '9' && formEdit.type === '5'">
              <label><span style="color:red;">* </span>消防水箱尺寸(单位：米)</label>
              <el-row style="display: flex;justify-content: space-between;padding-top:10px;">
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;" label="长" prop="equlong"><el-input v-model="formEdit.equlong" placeholder="请输入设备长度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;padding-left:10px;" label="宽" prop="equwide"><el-input v-model="formEdit.equwide" placeholder="请输入设备宽度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;padding-left:10px;" label="高" prop="equhigh"><el-input v-model="formEdit.equhigh" placeholder="请输入设备高度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item v-if="formEdit.type === '5'" label="设备型号" prop="equcode">
              <el-select v-model="formEdit.equcode" placeholder="下拉选择设备型号"  style="width: 100%;" disabled>
<!--                <el-option-->
<!--                  v-for="item in ctpModelOption"-->
<!--                  :key="item.equcode"-->
<!--                  :label="item.equcode"-->
<!--                  :value="item.equcode">-->
<!--                </el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '5'" label="设备编号" prop="equimei">
              <el-input v-model="formEdit.equimei" placeholder="请输入设备编号" style="width: 90%;" disabled></el-input>
              <div class="tipCont" v-show="imeiTip">
                <div class="tcBox">
                  <span class="triangle"></span>说明： 当选择消防主机联网设备，系统默认设备编号勿修改，若提示设备编号已存在，请刷新，该编号用于用传设备设备编码！当选择其他类型设备，输入正确的IMEI/ID，IMEI设备需提前导入
                </div>
              </div>
              <i class="el-icon-question codeTip" @click="showTip"></i>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '6'" label="设备功能" prop="equmark">
              <el-select v-model="formEdit.equmark" multiple placeholder="下拉选择设备功能"  style="width: 100%;">
                <el-option
                  v-for="item in downArray.equipFunction"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type === '3'" label="绑定单位" style="width: 100%;" required>
              <el-select v-model="formEdit.companyid" filterable placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%" @change="changeEditCom">
                <el-option v-for="item in downArray.companyList" :key="item.index"
                           :label="item.companyname"
                           :value="item.companyid">
                  {{ item.companyname }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formEdit.type !== '3'" label="绑定单位" style="width: 100%;">
              <el-select v-model="formEdit.companyid" filterable placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%" @change="changeEditCom">
                <el-option v-for="item in downArray.companyList" :key="item.index"
                           :label="item.companyname"
                           :value="item.companyid">
                  {{ item.companyname }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item >
              <template slot="label">
                <span>栋</span>
                <span style="margin-left: 150px">层</span>
              </template>
              <div class="test_floor" style="width: 100%">
                <el-select v-model="formEdit.equ_floor" placeholder="" style="width:150px;" @change="editBuildSelect">
                  <el-option
                    v-for="(item, index) in buildOption"
                    :key="index"
                    :label="item.buildname"
                    :value="item.buildname">
                  </el-option>
                </el-select>
                <el-select v-model="formEdit.equ_layer" placeholder="" style="width:80px;margin:0 10px;" @change="editFloorSelect">
                  <el-option
                    v-for="(item, index) in floorOption"
                    :key="index"
                    :label="item.label"
                    :value="item.label">
                    {{ item.label }}
                  </el-option>
                </el-select>
                <el-input v-model="formEdit.equ_adderss" style="width:150px;margin: 0px 8px 0px 12px;"  placeholder="具体设备位置" ></el-input>
                <el-button style="width:70px;padding: 0;margin-left:10px;" class="settingAdd" @click="changeEditCAD">关联图纸</el-button>
              </div>
            </el-form-item>
            <div class="test_point">
              <label class="from_label">设备图片</label>
              <Upload :fileType="'jpg/png/jpeg'" :picture="formEdit.equ_image" @uploadSuccess="editImgSuccess"></Upload>
              <p class="img_tip">支持.jpg.png.jpeg 格式</p>
            </div>
          </el-form>
          <div class="flexCE">
            <div class="settingCancel" @click="newFormEditCancel">取消</div>
            <div class="settingConfirm" @click="newFormEditHand">确定</div>
          </div>
        </div>
      </div>

      <!-- 编辑cad弹窗 -->
      <el-dialog
        append-to-body
        title="图纸定位"
        :visible.sync="dialogCADVisible"
        width="910px"
        center
        :before-close="handleCADClose"
        class="gyDialog unit_addDialog" :close-on-click-modal="false" >
        <div class="cad_p">
          <div class="cadImgBox">
            <img :src="editCADPosition" alt="" @click="handleEditCAD($event)">
            <span class="dian" :style="{top: y1 + 'px', left: x1 + 'px', display: active1}"></span>
          </div>
        </div>
        <div class="flexCE">
          <div class="settingCancel" @click="handleCADClose">取消</div>
          <div class="settingConfirm" @click="handCADConfirm">确定</div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { updateEquip } from '@/http/api/equip'
import { dropbuilding } from '@/http/api/build'
import Upload from '@/components/upload.vue'
export default {
  name: 'equipEdit',
  components: {
    Upload
  },
  props: ['downArray', 'formEdit'],
  data () {
    return{
      editDialog: true,
      companyid: '',
      selectCompanyId: '',
      editFormRule: {
        equimei: [{ required: true, message: '不能为空', trigger: 'blur' }],
        equcode: [{ required: true, message: '不能为空', trigger: 'blur' }],
        equtype: [{ required: true, message: '不能为空', trigger: 'blur' }],
        headport: [{ required: true, trigger: 'blur', message: 'Appkey不能为空' }],
        rtspport: [{ required: true, trigger: 'blur', message: 'Secret不能为空' }],
        companyid: [{ required: true, trigger: 'blur', message: '单位不能为空' }],
        equmace: [{ required: true, trigger: 'blur', message: '设备厂家不能为空' }],
        manufacturer: [{ required: true, trigger: 'blur', message: '设备厂家不能为空' }],
        equmark: [{ required: true, trigger: 'blur', message: '设备功能不能为空' }],
        equlong: [{ required: true, trigger: 'blur', message: '设备长度不能为空' }],
        equwide: [{ required: true, trigger: 'blur', message: '设备宽度不能为空' }],
        equhigh: [{ required: true, trigger: 'blur', message: '设备高度不能为空' }]
      },
      imeiTip: false,
      isSuper: true,
      buildOption: [],
      floorOption: [],
      dialogCADVisible: false,
      editCADPosition: '',
      x1: '',
      y1: '',
      active1: 'block',
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }

    this.editCADPosition = this.formEdit.equ_cadress
    if (this.formEdit.equmark === '' || this.formEdit.equmark === null) {
      this.formEdit.equmark = ''
    } else {
      this.formEdit.equmark = this.formEdit.equmark.split(',')
    }

    if (this.formEdit.companyid === '' || this.formEdit.companyid === undefined || this.formEdit.companyid === null) {
      this.buildOption = []
      this.floorOption = []
      this.formEdit.equ_floor = ''
      this.formEdit.equ_layer = ''
      this.formEdit.equ_adderss = ''
    } else {
      this.selectCompanyId = this.formEdit.companyid
      this.floorOption = []
      this.buildDrop()
      setTimeout(() => {
        this.floorOption = this.test(this.formEdit.equ_floor, this.buildOption)
      }, 1000)
    }
  },
  mounted () {
  },
  methods: {
    showTip () {
      this.imeiTip = !this.imeiTip
    },
    editBuildSelect (val) {
      this.formEdit.equ_layer = ''
      this.formEdit.equ_adderss = ''
      this.floorOption = this.test(val, this.buildOption)
    },
    editFloorSelect (val) {
      this.floorOption.forEach((item) => {
        if (item.label === val) {
          this.editCADPosition = item.path
          this.x1 = ''
          this.y1 = ''
        }
      })
    },
    test (str, arr) {
      let s = ''
      arr.forEach(element => {
        if (str === element.buildname) {
          s = element.buildmark
        }
      })
      return s
    },
    /* 单位绑定 编辑  */
    changeEditCom (val) {
      this.selectCompanyId = val
      this.formEdit.equ_floor = ''
      this.formEdit.equ_layer = ''
      this.formEdit.equ_adderss = ''
      this.buildOption = []
      this.floorOption = []
      this.buildDrop()
    },
    /* 建筑下拉 */
    buildDrop () {
      dropbuilding({ companyid: this.selectCompanyId }).then(res => {
        if (res.status === '1') {
          this.buildOption = res.data
        } else {
          this.buildOption = []
        }
      })
    },
    changeEditCAD () {
      if (this.formEdit.equ_floor !== '' && this.formEdit.equ_layer !== '') {
        this.dialogCADVisible = true
        let arr = []
        if (this.formEdit.equ_cadpoint === '' || this.formEdit.equ_cadpoint === null) { arr = [] }
        else { arr = this.formEdit.equ_cadpoint.split(',') }
        this.x1 = arr[0]
        this.y1 = arr[1]
      } else {
        this.$message.warning('请选择栋/层')
      }
    },
    handleEditCAD () {
      this.active1 = 'block'
      this.x1 = event.offsetX
      this.y1 = event.offsetY
      this.formEdit.equ_cadpoint = this.x1 + ',' + this.y1
    },
    handleCADClose () {
      this.dialogCADVisible = false
    },
    /* cad编辑确定 */
    handCADConfirm () {
      if (this.formEdit.equ_cadpoint !== '') {
        this.dialogCADVisible = false
      } else {
        this.$message.warning('还未选择设备安装点位')
      }
    },
    editImgSuccess (res) {
      this.formEdit.equ_image = res.filepath
    },
    /* 取消 */
    newFormEditCancel () {
      this.$emit('closeEditFrom')
    },
    /* 编辑提交 */
    newFormEditHand () {
      this.formEdit.equ_cadress = this.editCADPosition
      if (this.formEdit.equmark === '' || this.formEdit.equmark === null) {
        this.formEdit.equmark = ''
      } else {
        this.formEdit.equmark = this.formEdit.equmark.join(',')
      }
      this.$refs.FromEdit.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.formEdit.companyid,
          equ_floor: this.formEdit.equ_floor,
          equ_layer: this.formEdit.equ_layer,
          equ_adderss: this.formEdit.equ_adderss,
          equ_cadress: this.formEdit.equ_cadress,
          equ_cadpoint: this.formEdit.equ_cadpoint,
          equid: this.formEdit.equid,
          type: this.formEdit.type,
          equ_image: this.formEdit.equ_image,
          equmark: this.formEdit.equmark,
          equlong: this.formEdit.equlong,
          equwide: this.formEdit.equwide,
          equhigh: this.formEdit.equhigh,
        }
        updateEquip(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.$emit('editSuccess')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper {
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.from_tab{
  width: 100%;
  position: relative;
}
.tabCont{
  padding: 20px 0px 0px;
}
.eqlong >>> .el-form-item__label:before{
  display: none;
}
.codeTip{
  font-size: 30px;
  box-sizing: border-box;
  color: #196DF7;
  position: absolute;
  bottom: 3px;
  right: 10px;
  cursor: pointer;
}
.tipCont{
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 40px;
}
.tcBox{
  width: 100%;
  height: 100%;
  position: relative;
  background: #4295FF;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  line-height: 20px;
  border-radius: 5px;
}
.triangle{
  width: 0px;
  height: 0px;
  position: absolute;
  line-height: 0px;
  right: 20px;
  bottom: -8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid rgb(66 149 255);
}
.test_floor {
  width: 390px;
  position: relative;
  top: 0;
  left: 0;
  margin-top: 24px;
}
.cad_p {
  width: 860px;
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cadImgBox{
  width: auto;
  height: auto;
  position: relative;
}
.cadImgBox img {
  width: 100%;
  height: 100%;
}
.dian{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  display: none;
}
</style>
